@tailwind base;
@tailwind components;
@tailwind utilities;

.font-primary {
  font-family: "Space Grotesk", sans-serif;
}

.font-secondary {
  font-family: "Poppins", sans-serif;
}

.hero-screen {
  height: calc(100vh - 5rem);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #252323;
  border-radius: 10px;
}
